#our-projects .project {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .3rem;
    border-radius: var(--border-radius);
}

.project .image-container {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.project .text-container {
    padding: var(--spacing-1);
    padding-top: .1rem;
}

.nested-img-container {
    display: flex;
    height: 400px;
    border-radius: 8px;
}

.project img {
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: filter ease-in-out .3s;
    object-fit: cover;
}

.project .img-hvr {
    filter: unset;
}

.project p {
    font-size: var(--fs-regular);
}

.project h1 {
    font-size: var(--fs-semilarge);
    font-weight: var(--fw-bold);
    margin-bottom: .5rem;
}

.project .project__specifications {
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.project .project-context {
    background-image: linear-gradient(rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.7));
    font-weight: bold;
    color: white;
    position: absolute;
    bottom: -100%;
    width: 100%;
    z-index: 5;
    transition: top, bottom .3s ease-in-out;
}

.project .project-context .inside-project-context {
    padding: 1rem;
}

.project .project-context.visible {
    bottom: 0%;
}

.project .project__specifications>div {
    display: flex;
    gap: .5rem;
}