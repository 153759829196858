.service-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-2) var(--spacing-1);
    justify-content: space-between;
    text-align: center;
    border: 1px solid var(--container-divider-clr);
    /* width: 100%; */
    gap: var(--spacing-1);
    transition: background-color ease-in-out .2s
}

.service-div:hover {
    background-color: var(--container-divider-clr);
}

.service-div h1 {
    font-size: var(--fs-regular);
    font-weight: bold;
}

.service-div p {
    font-size: var(--fs-small);
}