/* 

#contact-us .section-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
}

.contact {
    display: flex;
    flex-direction: column;
}

.contact > div {
    display: flex;
    gap: 5px;
}

.contact h1 {
    font-size: var(--fs-regular);
    font-weight: bold;
} */

#contact-us {
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 0.5rem;
}

#contact-us .contact-btn {
    /* margin-left: auto; */
    width: fit-content;
    border-radius: 100%;
    cursor: pointer;
    padding: 0.6rem;
    display: flex;
    gap: 1rem;
    font-weight: bold;
}

.cntct {
    /* margin-right: auto; */
    /* font-weight: bold; */
    border-radius: 7px;
    border-bottom: 1px solid var(--container-divider-clr);
}

.cntct:last-of-type {
    border-bottom: 0;
}

.cntct:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

#contact-us a {
    display: flex;
    /* align-items: center; */
}