#our-team .section-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--spacing-1);
}

#our-team .element {
    padding: var(--spacing-1) var(--spacing-1);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
    /* border: 1px solid var(--container-divider-clr); */
    text-align: center;
}

#our-team .element h1 {
    font-size: var(--fs-regular);
    font-weight: var(--fw-bold);
}

#our-team .element p {

}

@media only screen and (max-width: 920px) {
    #our-team .section-content {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    #our-team .section-content {
        grid-template-columns: 1fr;
    }
}