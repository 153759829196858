@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;700&display=swap');

:root {
    --section-padding: 23%;

    --background-clr: #F5F3F0;
    --primary-text-clr: #313131;
    --secondary-text-clr: var(--primary-text-clr);
    --container-div-clr: #FFFFFF;
    --container-header-clr: #7B7B7B;
    --container-divider-clr: #E3E3E3;
    --button-clr: var(--primary-text-clr);
    --button-bg-clr: #FFFFFF;

    --box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, .25);

    --fw-thick: 1000;
    --fw-bold: 700;
    --fw-regular: 300;
    --fw-light: 100;

    --fs-tiny: 0.813rem;
    --fs-small: 0.9rem;
    --fs-regular: 1rem;
    --fs-regular-2: 1.1rem;
    --fs-semilarge: 1.2rem;
    --fs-large: 1.35rem;
    --fs-larger: 2.5rem;
    --fs-huge: 4rem;
    /* ?? */

    --border-radius: 7px;

    --spacing-1: 1rem;
    --spacing-2: 2rem;
    --spacing-3: 3rem;
}

body {
    width: 100%;
    height: 100%;
    background-color: var(--background-clr);
    color: var(--primary-text-clr);
}

#root {
    position: relative;
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
    color: unset;
}

a:visited {
    color: unset;
}

a:hover {
    cursor: pointer;
    color: unset;
}

a:focus {
    color: unset;
}

.flex-row {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.align-center {
    text-align: center;
}

button {
    padding: var(--spacing-1) var(--spacing-3);
    border: 0;
    border-radius: var(--border-radius);
}

.container-div {
    padding: var(--spacing-1);
    padding-bottom: var(--spacing-2);
}

section {
    padding: var(--spacing-1);
    padding-bottom: var(--spacing-2);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--container-div-clr);
    border: 0;
    border-radius: 7px;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, .25);
}

section .section-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

section .section-header h1 {
    font-size: var(--fs-large);
    color: var(--container-header-clr);
}

section .section-header hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: var(--container-divider-clr);
}

.side-section {
    padding-bottom: var(--spacing-1);
}

.main {
    /* width: 100%; */
}

.phone-btn {
    display: none;
    background-color: var(--container-div-clr);
    padding: 10px;
    position: fixed;
    z-index: 99;
    border-radius: var(--border-radius);
    border: 3px solid var(--container-divider-clr);
    bottom: 40px;
}

.floating-phone-btn {
    background-color: var(--container-div-clr);
    padding: 10px;
    z-index: 99;
    border-radius: var(--border-radius);
    border: 3px solid var(--container-divider-clr);
}

.phone-elements {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 98;
    opacity: 0;
    gap: 10px;
    transition: bottom .4s, opacity .2s;
    bottom: -120px;
}

.phone-elements.visible {
    opacity: 1;
    height: unset !important;
    bottom: 120px;
}

.main-container {
    display: relative;
}

@media only screen and (max-width: 1360px) {
    :root {
        --section-padding: var(--spacing-1);
    }
}

@media only screen and (max-width: 500px) {
    .phone-btn{
        display: unset;
    }
    :root {
        --section-padding: 0;
    }
}